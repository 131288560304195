<template>
  <!-- #region Rapor Önizleme -->
  <b-col v-if="show" ref="preview" cols="12" class="mt-1">
    <h2 class="mb-1">{{ $t("reportcrud.4.report_preview") }}</h2>
    <common-report-table
      v-if="show && reportType === 1"
      :report-name="$store.state.reportCrud.report_name"
      report-master-id="2"
      :table-data="reportData"
      :table-settings="settings"
      @ready="scroll()"
    />
    <common-report-chart
      v-else-if="show && reportType === 3"
      report-master-id="2"
      :chart-data="reportData"
      :chart-settings="settings"
      @ready="scroll()"
    />
    <common-report-pivot
      v-else-if="show && reportType === 4"
      report-master-id="2"
      :pivot-data="reportData"
      :pivot-settings="settings"
      @updateSlice="updateSlice"
      @ready="scroll()"
    />
    <common-report-calendar
      v-else-if="show && reportType === 5"
      report-master-id="2"
      :calendar-data="reportData"
      :calendar-settings="settings"
      @ready="scroll()"
    />
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { BCol } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { SET_REPORT_TYPE_OBJECT } from "@/store/services/report-crud";
import CommonReportTable from "../../components/CommonReportTable.vue";
import CommonReportChart from "../../components/CommonReportChart.vue";
import CommonReportPivot from "../../components/CommonReportPivot.vue";
import CommonReportCalendar from "../../components/CommonReportCalendar.vue";

export default {
  name: "preview-report",
  components: {
    BCol,
    CommonReportTable,
    CommonReportChart,
    CommonReportPivot,
    CommonReportCalendar,
  },
  data() {
    return {
      show: false,
      settings: {},
    };
  },
  beforeDestroy() {
    this.$root.$off("previewReport");
  },
  computed: {
    ...mapGetters([
      "reportType",
      "reportData",
      "reportTypeObject",
      "attachVariables",
    ]),
    type() {
      return this.reportType;
    },
  },
  methods: {
    updateSlice(slice) {
      this.$store.commit(
        SET_REPORT_TYPE_OBJECT,
        JSON.stringify(slice, null, 2)
      );
    },
    scroll() {
      setTimeout(() => {
        const scroll = this.$refs.preview;
        scroll.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
  },
  mounted() {
    this.$root.$on("previewReport", () => {
      new Promise((resolve) => {
        this.show = false;
        resolve();
      })
        .then(() => {
          let temp = this.reportTypeObject;
          this.attachVariables.forEach((item) => {
            temp = temp.replaceAll(`#${item.name}#`, item.value);
          });
          this.settings = JSON.parse(temp);
        })
        .then(() => {
          this.show = true;
        });
    });
  },
  watch: {
    type() {
      this.show = false;
    },
  },
};
</script>