<template>
  <!-- #region Filtreler -->
  <b-col cols="12" md="3" lg="4">
    <h2 class="mb-1">{{ $t("reportcrud.3.filters") }}</h2>
    <b-row class="sql-filters-container py-1 border border-primary rounded">
      <b-col
        cols="12"
        md="6"
        lg="3"
        v-for="(item, index) in filters"
        :key="index"
      >
        <b-button
          v-b-tooltip.hover.bottom="
            item.name + ' => ' + replaceSQLVariable(item)
          "
          variant="primary"
          block
          @click="addFilterInSQL(item.SQLVariable)"
          class="sql-filter"
        >
          <div>
            <feather-icon icon="FilterIcon" />
            <p class="p-0 m-0">{{ item.name }}</p>
          </div>
        </b-button>
      </b-col>
    </b-row>
  </b-col>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
.sql-filters-container {
  height: 400px;
  max-height: 400px;
  width: 100%;
  overflow-y: auto;
}
.sql-filter {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  flex-direction: column;
  height: 25%;
  min-height: 50px;
  div {
    width: 100%;
    svg {
      height: 25px;
      width: 25px;
    }
    p {
      white-space: nowrap;
      font-size: 12px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<script>
import { BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { replaceSQLVariable } from "@/views/Report/components/js/replaceSQLVariable";
import { ADD_QUERY } from "@/store/services/report-crud";

export default {
  name: "phase-three-filters",
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapGetters(["filters"]),
  },
  methods: {
    addFilterInSQL(filter) {
      this.$store.commit(ADD_QUERY, `{${filter}}`);
    },
    replaceSQLVariable,
  },
};
</script>