<template>
  <!-- #region Filtre Objesi -->
  <b-col cols="12" md="4" class="mb-1">
    <b-row>
      <b-col cols="12">
        <h2>{{ $t("reportcrud.2.filter_object") }}</h2>
        <prism class="filter-editor rounded mt-1" language="javascript">
          filter:{{ processFilter }}
        </prism>
      </b-col>
    </b-row>
  </b-col>
  <!-- #endregion -->
</template>
<style lang="scss" scoped>
.filter-editor {
  width: 100%;
  max-height: 700px;
}
</style>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Prism from 'vue-prism-component'
import 'prismjs'
import { mapGetters } from 'vuex'

export default {
  name: 'filter-object',
  components: {
    BRow,
    BCol,
    Prism,
  },
  computed:{
    ...mapGetters(['processFilter'])
  },
}
</script>