<template>
  <!-- #region Database ve SQL Sorgusu -->
  <b-row>
    <!-- #region Database -->
    <b-col v-if="processFilter.SQLIsRun" cols="12">
      <b-form-group
        :label="$t('reportcrud.2.filter_settings.database_title')"
        label-for="filter-sql-database"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('reportcrud.2.filter_settings.database_title')"
          rules="required"
        >
          <treeselect
            v-if="$store.state.reportCrud.databasesReady"
            id="filter-sql-database"
            v-model="processFilter.SQLDataBase"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="databases"
            :placeholder="
              $t('reportcrud.2.filter_settings.database_placeholder')
            "
            :class="errors.length > 0 ? 'is-invalid' : ''"
          />
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
    </b-col>
    <!-- #endregion -->
    <!-- #region SQL Sorgusu -->
    <b-col
      v-if="processFilter.SQLDataBase != null && processFilter.SQLIsRun"
      cols="12"
      class="mb-1"
    >
      <h3 class="d-flex justify-content-between mb-1">
        {{ $t("reportcrud.2.filter_settings.query_title") }}
        <b-button variant="warning" size="sm" @click="runSQL()">
          <span class="align-middle">{{
            $t("reportcrud.2.filter_settings.run_query")
          }}</span>
          <feather-icon icon="CpuIcon" class="ml-50" />
        </b-button>
      </h3>
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.2.filter_settings.query_title')"
        rules="required"
      >
        <prism-editor
          v-model="processFilter.SQLQuery"
          :class="'filter-sql-editor rounded ' + skin + '-editor'"
          :highlight="highlighterSQL"
        />
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-col>
    <!-- #endregion -->
  </b-row>
  <!-- #endregion -->
</template>

<style lang="scss">
.filter-sql-editor {
  height: 75px;
  max-height: 75px;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  .prism-editor__editor {
    background: none !important;
    color: #ccc;
  }
}
</style>

<script>
import { ValidationProvider } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";

import { PrismEditor } from "vue-prism-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-sql";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import Treeselect from "@riophae/vue-treeselect";
import { RUN_SQL } from "@/store/services/report-service";
import { mapGetters } from "vuex";

export default {
  name: "filter-settings-sql",
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    PrismEditor,
    Treeselect,
  },
  computed: {
    ...mapGetters(["databases", "processFilter", "appConfig/getThemeSkin"]),
    skin() {
      return this["appConfig/getThemeSkin"];
    },
  },
  methods: {
    runSQL() {
      this.$store
        .dispatch(RUN_SQL, {
          sql: this.processFilter.SQLQuery,
          database_id: this.processFilter.SQLDataBase,
        })
        .then((response) => {
          if (response.status && response.data != null) {
            this.processFilter.value = null;
            this.processFilter.selectOptions = [];
            response.data.forEach((item) => {
              const entries = Object.entries(item);
              this.processFilter.selectOptions.push({
                id: entries[0][1] ? entries[0][1].toString() : "NULL",
                label: entries[1][1] ? entries[1][1].toString() : "Tanımsız",
              });
            });
          }
        });
    },
    highlighterSQL(code) {
      return highlight(code, languages.sql);
    },
  },
};
</script>