<template>
  <!--#region Rapor Grubu -->
  <b-col cols="12" md="4">
    <b-form-group :label="$t('reportcrud.1.group')" label-for="report-group">
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.1.group')"
        rules="required"
      >
        <b-input-group>
          <treeselect
            v-if="ready"
            id="report-group"
            v-model="report_group_id"
            :options="groups"
            :placeholder="$t('reportcrud.1.group_placeholder')"
            :show-count="true"
            class="report-group-select"
            :class="errors.length > 0 ? 'is-invalid' : ''"
          />

          <b-input-group-append>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.new-report-group-modal
              variant="primary"
              size="md"
              block
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
    <report-group-modal v-if="ready" ref="newGroup" />
  </b-col>
  <!--#endregion -->
</template>

<style lang="scss">
.report-group-select {
  flex: 1 1 auto;
  width: 1% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";
import Ripple from "vue-ripple-directive";

import { mapGetters } from "vuex";
import ReportGroupModal from "./ReportGroupModal.vue";
import { GET_REPORT_GROUPS } from "@/store/services/report-service";
import { UPDATE_REPORT_GROUP_ID } from "@/store/services/report-crud";

export default {
  name: "report_group",
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    Treeselect,
    ReportGroupModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ready: false,
      groups: [],
    };
  },
  created() {
    this.getReportGroups().then(() => {
      this.ready = true;
    });
  },
  computed: {
    ...mapGetters(["reportGroup"]),
    report_group_id: {
      get() {
        return this.reportGroup;
      },
      set(val) {
        this.$store.commit(UPDATE_REPORT_GROUP_ID, val);
      },
    },
  },
  methods: {
    getReportGroups() {
      return new Promise((resolve) => {
        this.$store.dispatch(GET_REPORT_GROUPS).then((response) => {
          this.groups = response.set_report_groups;
          resolve();
        });
      });
    },
  },
};
</script>