<template>
  <!-- #region Rapor Objesi Editor -->
  <b-col cols="12" md="4" class="mb-1">
    <div class="mt-1">
      <h2 class="d-flex justify-content-between mb-1">
        {{ $t("reportcrud.4.report_object") }}
        <div class="buttons">
          <b-button variant="primary" size="sm" @click="preview()">
            <span class="align-middle">{{ $t("reportcrud.4.preview") }}</span>
            <feather-icon icon="SearchIcon" class="ml-50" />
          </b-button>
        </div>
      </h2>
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.4.report_type_object')"
        rules="required"
      >
        <prism-editor
          v-model="object"
          :class="'report-type-editor rounded ' + skin + '-editor'"
          :highlight="highlighterJS"
        />
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </div>
  </b-col>
  <!-- #endregion -->
</template>

<style lang="scss">
.report-type-editor {
  height: 500px;
  max-height: 500px;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  .prism-editor__editor {
    background: none !important;
    color: #ccc;
  }
}
</style>

<script>
import { ValidationProvider } from 'vee-validate'
import { BCol, BFormInvalidFeedback, BButton } from 'bootstrap-vue'
import { PrismEditor } from 'vue-prism-editor'
import { highlight, languages } from 'prismjs/components/prism-core'

import { mapGetters } from 'vuex'
import { SET_REPORT_TYPE_OBJECT } from '@/store/services/report-crud'

export default {
  name: 'report-type-settings-editor',
  components: {
    ValidationProvider,
    BCol,
    BFormInvalidFeedback,
    BButton,
    PrismEditor,
  },
  computed: {
    ...mapGetters(['reportTypeObject', 'appConfig/getThemeSkin']),
    object:{
      get(){
        return this.reportTypeObject
      },
      set(val){
        this.$store.commit(SET_REPORT_TYPE_OBJECT,val)
      }
    },
    skin() {
      return this['appConfig/getThemeSkin']
    },
  },
  methods: {
    highlighterJS(code) {
      return highlight(code, languages.js)
    },
    preview() {
      this.$root.$emit('previewReport')
    },
  },
}
</script>