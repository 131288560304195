<template>
  <b-row>
    <b-col cols="12">
      <!-- <form-content /> -->
      <form-wizard
        ref="wizard"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        :finish-button-text="$t('reportcrud.save')"
        :back-button-text="$t('reportcrud.prev')"
        :next-button-text="$t('reportcrud.next')"
        class="steps-transparent mb-3"
        @on-complete="saveReport"
      >
        <tab-content
          ref="tab1"
          :title="$t('reportcrud.1.title')"
          icon="feather icon-file-text"
          :before-change="checkPhase1"
        >
          <phase-one ref="p1" />
        </tab-content>
        <tab-content
          :title="$t('reportcrud.2.title')"
          icon="feather icon-filter"
          :before-change="checkPhase2"
        >
          <phase-two ref="p2" />
        </tab-content>
        <tab-content
          :title="$t('reportcrud.3.title')"
          icon="feather icon-code"
          :before-change="checkPhase3"
        >
          <phase-three ref="p3" />
        </tab-content>
        <tab-content
          :title="$t('reportcrud.4.title')"
          icon="feather icon-pie-chart"
          :before-change="checkPhase4"
        >
          <phase-four ref="p4" />
        </tab-content>
      </form-wizard>
    </b-col>
  </b-row>
</template>

<style lang="scss">
.dark-editor {
  background: #161d31 !important;
}
.light-editor {
  background: #2d2d2d !important;
}
.prism-editor__textarea:focus {
  outline: none;
}
.next-prev-short {
  display: flex;
  position: absolute;
  top: 0;
  right: 20px;
  flex-direction: row;
  gap: 2rem;
  a {
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}
</style>

<script>
import { TabContent, FormWizard } from "vue-form-wizard";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "prismjs/themes/prism-tomorrow.css";
import "vue-prism-editor/dist/prismeditor.min.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";

import { BRow, BCol } from "bootstrap-vue";
import PhaseOne from "./1/PhaseOne.vue";
import PhaseTwo from "./2/PhaseTwo.vue";
import PhaseThree from "./3/PhaseThree.vue";
import PhaseFour from "./4/PhaseFour.vue";
import {
  DEFAULT_REPORT,
  GET_REPORT,
  SAVE_REPORT,
  UPDATE_REPORT,
} from "@/store/services/report-crud";

export default {
  name: "form-content",
  components: {
    TabContent,
    FormWizard,
    BRow,
    BCol,
    PhaseOne,
    PhaseTwo,
    PhaseThree,
    PhaseFour,
  },
  props: {
    isNew: { required: true, type: Boolean },
  },
  created() {
    if (this.isNew) {
      this.$store.commit(DEFAULT_REPORT);
    } else {
      this.$store.dispatch(GET_REPORT, this.$route.params.id * 1);
    }
  },
  beforeDestroy() {
    this.$store.commit(DEFAULT_REPORT);
    this.$root.$off("nextTab");
    this.$root.$off("prevTab");
  },
  methods: {
    checkPhase1() {
      return new Promise((resolve, reject) => {
        this.$refs.p1.check().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    checkPhase2() {
      return new Promise((resolve, reject) => {
        this.$refs.p2.check().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    checkPhase3() {
      return new Promise((resolve, reject) => {
        this.$refs.p3.check().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    checkPhase4() {
      return new Promise((resolve, reject) => {
        this.$refs.p4.check().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    saveReport() {
      if (this.isNew) {
        this.$store.dispatch(SAVE_REPORT).then((response) => {
          this.$router.push({
            name: "/Report/Display",
            params: { id: response.report_m_id },
          });
        });
      } else {
        this.$store
          .dispatch(UPDATE_REPORT, this.$route.params.id * 1)
          .then((response) => {
            this.$router.push({
              name: "/Report/Display",
              params: { id: response.report_m_id },
            });
          });
      }
    },
  },
  mounted() {
    if(!this.isNew){
      this.$refs.wizard.activateAll()
    }
    this.$root.$on("nextTab", () => {
      this.$refs.wizard.nextTab();
    });

    this.$root.$on("prevTab", () => {
      this.$refs.wizard.prevTab();
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>
