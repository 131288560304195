<template>
  <!-- #region Örnek Veri -->
  <b-col cols="12" md="4" class="my-1">
    <h2 class="mb-1">{{ $t("reportcrud.4.example_data") }}</h2>
    <prism class="report-example-editor rounded" language="javascript">
      {{ exampleQueryData }}
    </prism>
  </b-col>
  <!-- #endregion -->
</template>

<style lang="scss">
.report-example-editor {
  height: 500px;
}
</style>

<script>
import { BCol } from 'bootstrap-vue'
import Prism from 'vue-prism-component'
import 'prismjs'
import { mapGetters } from 'vuex'

export default {
  name: 'example-data',
  components: {
    BCol,
    Prism,
  },
  computed: {
    ...mapGetters(['reportData']),
    exampleQueryData() {
      if (this.reportData.length > 0) {
        return this.reportData[0]
      }
      return {}
    },
  },
}
</script>