<template>
  <!-- #region Tarih => Tarih Formatı -->
  <b-col cols="12">
    <b-form-group
      :label="$t('reportcrud.2.date_format_title')"
      label-for="filter-date-format"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.2.date_format_title')"
        rules="required"
      >
        <treeselect
          id="filter-date-format"
          v-model="dateFormat"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formats"
          :placeholder="$t('reportcrud.2.date_format_placeholder')"
          :class="errors.length > 0 ? 'is-invalid' : ''"
        />
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BCol, BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import { CHANGE_FORMAT } from '@/store/services/process-filter'
import { mapGetters } from 'vuex'

export default {
  name: 'filter-date-format',
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    Treeselect,
    BFormInvalidFeedback,
  },
  computed: {
    ...mapGetters(['processFilter']),
    dateFormat:{
      get(){
        return this.processFilter.dateFormat
      },
      set(val){
        this.$store.dispatch(CHANGE_FORMAT,val)
      }
    },
    formats() {
      return [
        { id: 'Y-m-d', label: this.$t('reportcrud.2.formats.0') },
        { id: 'd-m-Y', label: this.$t('reportcrud.2.formats.1') },
        { id: 'Y-m-d H:i', label: this.$t('reportcrud.2.formats.2') },
        { id: 'd-m-Y H:i', label: this.$t('reportcrud.2.formats.3') },
      ]
    },
  },
}
</script>