<template>
  <!--#region Rapor Tanım -->
  <b-col cols="12" md="4">
    <b-form-group :label="$t('reportcrud.1.database')" label-for="database">
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.1.database')"
        rules="required"
      >
        <b-overlay
          :show="!$store.state.reportCrud.databasesReady"
          variant="dark"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <treeselect
            id="database"
            v-model="$store.state.reportCrud.database_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="databases"
            :placeholder="$t('reportcrud.1.database_placeholder')"
            :class="errors.length > 0 ? 'is-invalid' : ''"
          />
        </b-overlay>
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/views/Report/components/scss/treeselect.scss";
import { mapGetters } from "vuex";
import { GET_DATABASES } from "@/store/services/report-crud";

export default {
  name: "report_database",
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
    Treeselect,
  },
  created() {
    this.$store.dispatch(GET_DATABASES);
  },
  computed: {
    ...mapGetters(["databases"]),
  },
};
</script>