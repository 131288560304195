<template>
  <!-- #region Rapor Objesi Değişkenleri -->
  <b-col cols="12" md="4" class="my-1">
    <h2 class="mb-1">{{ $t("reportcrud.4.attach_variables_title") }}</h2>
    <div class="report-attach-variables-container border rounded p-1">
      <div v-if="attachVariables.length > 0">
        <div v-for="item in attachVariables" :key="item.name" class="variable">
          <!-- #region Rapor Objesi Değişkeni -->
          <b-form-group
            :label="item.name"
            :label-for="'report-object-variable-' + item.name"
          >
            <validation-provider
              #default="{ errors }"
              :name="item.name"
              rules="required"
            >
              <treeselect
                :id="'report-object-variable-' + item.name"
                v-model="item.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sqlHeaders"
                :placeholder="$t('reportcrud.4.attach_variables_placeholder')"
                :class="errors.length > 0 ? 'is-invalid' : ''"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
          <!-- #endregion -->
        </div>
      </div>
      <div v-else class="text-center">
        <span>
          <b v-html="$t('reportcrud.4.attach_variables_empty_placeholder')"></b>
        </span>
      </div>
    </div>
  </b-col>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
.report-attach-variables-container {
  height: 500px;
  overflow: auto;
}
</style>

<script>
import { ValidationProvider } from 'vee-validate'
import { BCol, BFormGroup,BFormInvalidFeedback } from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import { mapGetters } from 'vuex'

export default {
  name: 'attach-variables',
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    Treeselect,
  },
  computed: {
    ...mapGetters(['attachVariables','sqlHeaders']),
  },
}
</script>