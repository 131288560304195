<template>
  <!-- #region Filtre Adı -->
  <b-col cols="12">
    <b-form-group :label="$t('reportcrud.2.filter_name')" label-for="filter-name">
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.2.filter_name')"
        rules="required"
      >
        <b-form-input
          id="filter-name"
          v-model="processFilter.name"
          :state="errors.length > 0 ? false : null"
          :placeholder="$t('reportcrud.2.filter_name_placeholder')"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BCol,
  BFormGroup,
  BFormInput
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'filter-name',
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInput,
  },
  computed:{
    ...mapGetters(['processFilter'])
  }

}
</script>