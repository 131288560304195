var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.processFilter.option != null &&
    (_vm.processFilter.type == 0 ||
      (_vm.processFilter.type == 1 && _vm.processFilter.selectOptions.length > 0) ||
      (_vm.processFilter.type == 2 && _vm.processFilter.dateFormat != null))
  )?_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("reportcrud.2.sql_variable.settings")))]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":_vm.processFilter.type == 1 ? 6 : 12}},[_c('b-form-group',{attrs:{"label":_vm.$t('reportcrud.2.sql_variable.title'),"label-for":"filter-sql-variable"}},[_c('validation-provider',{attrs:{"name":_vm.$t('reportcrud.2.sql_variable.title'),"rules":{
            required: true,
            regex: /^[a-z_]\w*$/,
            max: 15,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"filter-sql-variable","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('reportcrud.2.sql_variable.placeholder')},model:{value:(_vm.processFilter.SQLVariable),callback:function ($$v) {_vm.$set(_vm.processFilter, "SQLVariable", $$v)},expression:"processFilter.SQLVariable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3208222522)})],1)],1),(_vm.processFilter.type == 1)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('reportcrud.2.sql_variable.use'),"label-for":"filter-sql-replace"}},[_c('validation-provider',{attrs:{"name":_vm.$t('reportcrud.2.sql_variable.use'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('treeselect',{class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"filter-sql-replace","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.types,"placeholder":_vm.$t('reportcrud.2.sql_variable.use_placeholder')},model:{value:(_vm.processFilter.SQLReplaceType),callback:function ($$v) {_vm.$set(_vm.processFilter, "SQLReplaceType", $$v)},expression:"processFilter.SQLReplaceType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,2941360847)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("reportcrud.2.sql_variable.preview")))]),_c('prism',{staticClass:"sql-variable-editor rounded mt-1",attrs:{"language":"javascript"}},[_vm._v(" "+_vm._s("{" + _vm.processFilter.SQLVariable + "}")+" => "+_vm._s(_vm.replaceSQLVariable(_vm.processFilter))+" ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }