<template>
  <!-- #region Filtre SQL Değişkeni -->
  <b-col
    v-if="
      processFilter.option != null &&
      (processFilter.type == 0 ||
        (processFilter.type == 1 && processFilter.selectOptions.length > 0) ||
        (processFilter.type == 2 && processFilter.dateFormat != null))
    "
    cols="12"
  >
    <h2>{{ $t("reportcrud.2.sql_variable.settings") }}</h2>
    <b-row>
      <!-- #region Değişken Adı -->
      <b-col cols="12" :md="processFilter.type == 1 ? 6 : 12">
        <b-form-group
          :label="$t('reportcrud.2.sql_variable.title')"
          label-for="filter-sql-variable"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('reportcrud.2.sql_variable.title')"
            :rules="{
              required: true,
              regex: /^[a-z_]\w*$/,
              max: 15,
            }"
          >
            <b-form-input
              id="filter-sql-variable"
              v-model="processFilter.SQLVariable"
              :state="errors.length > 0 ? false : null"
              :placeholder="$t('reportcrud.2.sql_variable.placeholder')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- #endregion -->
      <!-- #region Değişken Ayarları -->
      <b-col v-if="processFilter.type == 1" cols="12" md="6">
        <b-form-group
          :label="$t('reportcrud.2.sql_variable.use')"
          label-for="filter-sql-replace"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('reportcrud.2.sql_variable.use')"
            rules="required"
          >
            <treeselect
              id="filter-sql-replace"
              v-model="processFilter.SQLReplaceType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="types"
              :placeholder="$t('reportcrud.2.sql_variable.use_placeholder')"
              :class="errors.length > 0 ? 'is-invalid' : ''"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- #endregion -->
      <!-- #region Değişken Önizleme -->
      <b-col cols="12">
        <h2>{{ $t("reportcrud.2.sql_variable.preview") }}</h2>
        <prism class="sql-variable-editor rounded mt-1" language="javascript">
          {{ "{" + processFilter.SQLVariable + "}" }} =>
          {{ replaceSQLVariable(processFilter) }}
        </prism>
      </b-col>
      <!-- #endregion -->
    </b-row>
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { ValidationProvider,extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import VueSlider from 'vue-slider-component'
import Treeselect from '@riophae/vue-treeselect'
import Prism from 'vue-prism-component'
import 'prismjs'
import { mapGetters } from 'vuex'
import { replaceSQLVariable } from '@/views/Report/components/js/replaceSQLVariable'

extend('max',max)

export default {
  name: 'filter-sql-variable',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    VueSlider,
    Treeselect,
    Prism,
  },
  computed: {
    ...mapGetters(['processFilter']),
    types() {
      return [
        {
          id: 'quote',
          label: this.$t('reportcrud.2.sql_variable.with_quotes'),
        },
        {
          id: 'pure',
          label: this.$t('reportcrud.2.sql_variable.without_quotes'),
        },
      ]
    },
  },
  methods: {
    replaceSQLVariable,
  },
}
</script>