<template>
  <!-- #region Filtre Özelliği -->
  <b-col cols="12">
    <b-form-group
      :label="$t('reportcrud.2.filter_option.title')"
      label-for="filter-option"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.2.filter_option.title')"
        rules="required"
      >
        <treeselect
          id="filter-option"
          v-model="option"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="options[type]"
          :placeholder="$t('reportcrud.2.filter_option.title')"
          :class="errors.length > 0 ? 'is-invalid' : ''"
        />
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BCol, BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue'

import Treeselect from '@riophae/vue-treeselect'
//import '@riophae/vue-treeselect/dist/vue-treeselect.css'
//import '@/views/Report/components/scss/treeselect.scss'
import { SET_OPTION } from '@/store/services/process-filter'
import { mapGetters } from 'vuex'

export default {
  name: 'filter-option',
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    Treeselect,
  },
  computed: {
    ...mapGetters(['processFilter']),
    type(){
      return this.processFilter.type
    },
    option:{
      get(){
        return this.processFilter.option
      },
      set(val){
        this.$store.commit(SET_OPTION,val)
      }
    },
    options() {
      return [
        [
          { id: 'text', label: this.$t('reportcrud.2.filter_option.text') },
          { id: 'number', label: this.$t('reportcrud.2.filter_option.number') },
        ],
        [
          { id: 'false', label: this.$t('reportcrud.2.filter_option.single') },
          { id: 'true', label: this.$t('reportcrud.2.filter_option.multiple') },
        ],
        [
          { id: 'single', label: this.$t('reportcrud.2.filter_option.single') },
          {
            id: 'multiple',
            label: this.$t('reportcrud.2.filter_option.multiple'),
          },
          { id: 'range', label: this.$t('reportcrud.2.filter_option.range') },
        ],
      ]
    },
  },
}
</script>