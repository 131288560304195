<template>
  <!--#region Rapor Tanım -->
  <validation-observer ref="phase" tag="form">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">{{ $t("reportcrud.1.title") }}</h5>
        <small class="text-muted">{{ $t("reportcrud.1.placeholder") }}</small>
        <div class="next-prev-short">
          <a v-b-tooltip.hover.top="$t('reportcrud.next')" @click="nextTab">
            <feather-icon icon="ArrowRightIcon" />
          </a>
        </div>
      </b-col>
      <report-name />
      <report-database />
      <report-group />
      <report-description />
    </b-row>
  </validation-observer>
  <!--#endregion -->
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { BRow, BCol, VBTooltip } from "bootstrap-vue";

import ReportName from "./ReportName.vue";
import ReportDatabase from "./Database.vue";
import ReportGroup from "./ReportGroup.vue";
import ReportDescription from "./Description.vue";

export default {
  name: "report-phase-one",
  components: {
    ValidationObserver,
    BRow,
    BCol,
    ReportName,
    ReportDatabase,
    ReportGroup,
    ReportDescription,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    check() {
      return new Promise((resolve) => {
        this.$refs.phase.validate().then((response) => {
          resolve(response);
        });
      });
    },
    nextTab() {
      this.$root.$emit("nextTab");
    },
  },
};
</script>