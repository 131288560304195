<template>
  <!--#region Rapor Açıklaması -->
  <b-col md="12">
    <b-form-group :label="$t('reportcrud.1.description')" label-for="info_text">
      <b-form-textarea
        id="info_text"
        v-model="$store.state.reportCrud.description"
        type="textarea"
        :placeholder="$t('reportcrud.1.description_placeholder')"
        rows="3"
        max-rows="4"
      />
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import { BCol, BFormGroup, BFormTextarea } from "bootstrap-vue";

export default {
  name: "report_description",
  components: {
    BCol,
    BFormGroup,
    BFormTextarea,
  },
};
</script>