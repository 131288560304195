<template>
  <!-- #region Filtre Ekle/Güncelle -->
  <b-modal
    ref="modal"
    hide-footer
    centered
    no-close-on-backdrop
    size="xl"
    :title="
      isNew
        ? $t('reportcrud.2.new_filter_title')
        : $t('reportcrud.2.update_filter_title')
    "
  >
    <validation-observer ref="filter" v-slot="{ invalid }" tag="form">
      <filter-generate/>
      <!-- #region Filtre Ekleme Footer -->
      <b-row class="filter-footer mt-5" @mouseover="checkFilter()">
        <b-button
          v-if="isNew"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          :disabled="invalid"
          @click="addFilter()"
        >
          {{ $t("reportcrud.2.save_filter") }}
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          :disabled="invalid"
          @click="updateFilter()"
        >
          {{ $t("reportcrud.2.update_filter") }}
        </b-button>
      </b-row>
      <!-- #endregion -->
    </validation-observer>
  </b-modal>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
.filter-footer {
  display: flex;
  padding: 5px 10px;
  justify-content: flex-end;
}
</style>

<script>
import { TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import FilterGenerate from './FilterGenerate.vue'
import { NEW_FILTER, UPDATE_FILTER } from '@/store/services/report-filters'

export default {
  name: 'filter-modal',
  props: {
    isNew: {
      required: true,
      type: Boolean
    },
    updateIndex: {
      required: true,
      type: Number
    },
  },
  components: {
    TabContent,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BModal,
    FilterGenerate,
  },
  directives: {
    Ripple,
  },
  methods: {
    show() {
      this.$refs.modal.show()
    },
    checkFilter() {
      this.$refs.filter.validate()
    },
    addFilter(){
      this.$store.dispatch(NEW_FILTER)
      this.$refs.modal.hide()
    },
    updateFilter(){
      this.$store.dispatch(UPDATE_FILTER,this.updateIndex)
      this.$refs.modal.hide()
    }
  }
}
</script>