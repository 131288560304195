<template>
  <!--#region Filtreler -->
  <validation-observer ref="phase" tag="form">
    <b-row>
      <!-- #region Header -->
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">{{ $t("reportcrud.2.title") }}</h5>
        <small class="text-muted">
          {{ $t("reportcrud.2.placeholder") }}
        </small>
        <div class="next-prev-short">
          <a v-b-tooltip.hover.top="$t('reportcrud.prev')" @click="prevTab">
            <feather-icon icon="ArrowLeftIcon" />
          </a>
          <a v-b-tooltip.hover.top="$t('reportcrud.next')" @click="nextTab">
            <feather-icon icon="ArrowRightIcon" />
          </a>
        </div>
      </b-col>
      <report-filters ref="filters"/>
      <filters-object/>
    </b-row>
  </validation-observer>
  <!-- #endregion -->
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'

import ReportFilters from './ReportFilters.vue'
import FiltersObject from './FiltersObject.vue'

export default {
  name: 'phase-two',
  components: {
    ValidationObserver,
    BRow,
    BCol,
    ReportFilters,
    FiltersObject,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    check() {
      return new Promise(resolve => {
        this.$refs.phase.validate().then(response => {
          resolve(response)
        })
      })
    },
    nextTab() {
      this.$root.$emit('nextTab')
    },
    prevTab() {
      this.$root.$emit('prevTab')
    },
  },
}
</script>