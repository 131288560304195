<template>
  <!-- #region Filtre Oluşturma -->
  <b-row class="filter-row">
    <b-col cols="12" md="4" class="mb-1">
      <h2>{{ $t("reportcrud.2.filter_options") }}</h2>
      <b-row>
        <filter-name />
        <filter-type />
        <filter-option v-if="processFilter.type != null" />
        <template v-if="processFilter.option != null">
          <filter-settings />
          <filter-select-options v-if="processFilter.type == 1 && !processFilter.SQLIsRun" />
          <filter-date-format v-if="processFilter.type == 2" />
        </template>
      </b-row>
    </b-col>
    <filter-preview />
    <filter-object />
  </b-row>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
.filter-row {
  min-height: 500px;
  padding: 10px 0;
}
</style>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FilterName from './FilterName.vue'
import FilterType from './FilterType.vue'
import FilterOption from './FilterOption.vue'
import FilterSettings from './FilterSettings.vue'
import FilterSelectOptions from './FilterSelectOptions.vue'
import FilterDateFormat from './FilterDateFormat.vue'
import FilterPreview from './FilterPreview.vue'
import FilterObject from './FilterObject.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'filter-generate',
  components: {
    BRow,
    BCol,
    FilterName,
    FilterType,
    FilterOption,
    FilterSettings,
    FilterSelectOptions,
    FilterDateFormat,
    FilterPreview,
    FilterObject,
  },
  computed: {
    ...mapGetters(['processFilter'])
  },
}
</script>