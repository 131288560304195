<template>
  <!--#region Sorgu -->
  <validation-observer ref="phase" tag="form">
    <!-- #region Header -->
    <b-row>
      <b-col cols="12" class="mb-1">
        <h5 class="mb-0">
          {{ $t("reportcrud.4.title") }}
          <b-button
            v-b-tooltip.hover.top="$t('reportcrud.4.help')"
            @click="helpModal()"
            variant="flat-info"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="20" icon="HelpCircleIcon" />
          </b-button>
        </h5>
        <small class="text-muted">
          {{ $t("reportcrud.4.placeholder") }}
        </small>
        <div class="next-prev-short">
          <a v-b-tooltip.hover.top="$t('reportcrud.prev')" @click="prevTab()">
            <feather-icon icon="ArrowLeftIcon" />
          </a>
          <a v-b-tooltip.hover.top="$t('reportcrud.save')" @click="nextTab()">
            <feather-icon icon="ArrowRightIcon" />
          </a>
        </div>
      </b-col>
    </b-row>
    <!-- #endregion -->
    <report-type />
    <b-row v-if="$store.state.reportCrud.report_type_id != null" class="mb-1">
      <report-type-settings-editor />
      <attach-variables />
      <example-data />
      <preview-report />
    </b-row>
    <help-modal ref="helpModal" />
  </validation-observer>
  <!-- #endregion -->
</template>


<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";
import ReportType from "./ReportType.vue";
import AttachVariables from "./AttachVariables.vue";
import ReportTypeSettingsEditor from "./ReportTypeSettingsEditor.vue";
import ExampleData from "./ExampleData.vue";
import PreviewReport from "./PreviewReport.vue";
import HelpModal from "./HelpModal.vue";

export default {
  name: "phase-four",
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BButton,
    AttachVariables,
    ReportTypeSettingsEditor,
    ReportType,
    ExampleData,
    PreviewReport,
    HelpModal,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    check() {
      return new Promise((resolve) => {
        this.$refs.phase.validate().then((response) => {
          resolve(response);
        });
      });
    },
    nextTab() {
      this.$root.$emit("nextTab");
    },
    prevTab() {
      this.$root.$emit("prevTab");
    },
    helpModal() {
      this.$refs.helpModal.showModal();
    },
  },
};
</script>