<template>
  <!-- #region Filtre Ayarları -->
  <b-row>
    <!-- #region Filtre Kolon Boyutu -->
    <b-col cols="12" :md="processFilter.type == 1 ? 6 : 12">
      <b-form-group
        :label="$t('reportcrud.2.filter_settings.column_size_title')"
        label-for="filter-col-size"
      >
        <vue-slider
          v-model="processFilter.colSize"
          :min="1"
          :max="12"
          :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        />
      </b-form-group>
    </b-col>
    <!-- #endregion -->
    <!-- #region Filtre SQL Sorgusu Aç/Kapa -->
    <b-col v-if="processFilter.type == 1" cols="12" md="6">
      <b-form-group
        :label="$t('reportcrud.2.filter_settings.with_sql_title')"
        label-for="filter-sql-is-run"
      >
        <b-form-checkbox
          :checked="processFilter.SQLIsRun"
          name="check-button"
          switch
          inline
          @change="updateSQLIsRun"
        >
          {{
            processFilter.SQLIsRun
              ? $t("reportcrud.2.filter_settings.yes")
              : $t("reportcrud.2.filter_settings.no")
          }}
        </b-form-checkbox>
      </b-form-group>
    </b-col>
    <!-- #endregion -->
  </b-row>
  <!-- #endregion -->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BRow,BCol, BFormGroup, BFormCheckbox } from 'bootstrap-vue'

import VueSlider from 'vue-slider-component'
import { mapGetters } from 'vuex'
import { TOGGLE_SQL_IS_RUN } from '@/store/services/process-filter'

export default {
  name: 'filter-settings-column',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    VueSlider,
  },
  computed:{
    ...mapGetters(['processFilter'])
  },
  methods:{
    updateSQLIsRun(){
      this.$store.commit(TOGGLE_SQL_IS_RUN)
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
</style>