<template>
  <!-- #region Rapor Tipi -->
  <b-row class="d-flex justify-content-center align-center">
    <b-col cols="12" md="4" class="mb-1">
      <b-form-group :label="$t('reportcrud.4.title')" label-for="report-type">
        <validation-provider
          #default="{ errors }"
          :name="$t('reportcrud.4.title')"
          rules="required"
        >
          <treeselect
            v-if="ready"
            id="report-type"
            v-model="type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="reportTypes"
            :placeholder="$t('reportcrud.4.select_placeholder')"
            :class="errors.length > 0 ? 'is-invalid' : ''"
          />
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
  <!-- #endregion -->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import { mapGetters } from 'vuex'
import { GET_REPORT_TYPES, SET_REPORT_TYPE } from '@/store/services/report-crud'

export default {
  name: 'report-type',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    Treeselect,
  },
  data() {
    return {
      ready: false,
    }
  },
  created() {
    this.$store.dispatch(GET_REPORT_TYPES).then(() => {
      this.ready = true
    })
  },
  computed: {
    ...mapGetters(['reportTypes', 'reportType']),
    type: {
      get(){
        return this.reportType
      },
      set(val) {
        this.$store.commit(SET_REPORT_TYPE, val)
      },
    },
  },
  methods: {
    showModal() {
      this.$refs.report_type_help_modal.show()
    },
  },
}
</script>