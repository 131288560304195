<template>
  <!-- #region Filtreler Objesi -->
  <b-col cols="12" md="6">
    <h2>{{$t('reportcrud.2.filters_object')}}</h2>
    <prism class="filters-editor rounded mt-1" language="javascript">
      filters:{{ filters }}
    </prism>
  </b-col>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
.filters-editor {
  width: 100%;
  height: 400px;
}
</style>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Prism from 'vue-prism-component'
import 'prismjs'
import { mapGetters } from 'vuex'

export default {
  name: 'filters-object',
  components: {
    BRow,
    BCol,
    Prism,
  },
  computed:{
    ...mapGetters(['filters'])
  }
}
</script>