<template>
  <!-- #region Filtre Çıktısı -->
  <b-col cols="12" md="4" class="mb-1">
    <b-row>
      <b-col
        v-if="
          processFilter.type == 0 ||
          (processFilter.type == 1 && processFilter.selectOptions.length > 0) ||
          (processFilter.type == 2 && processFilter.dateFormat != null)
        "
        cols="12"
      >
        <h2>{{ $t("reportcrud.2.filter_preview") }}</h2>
        <b-row>
          <b-col :cols="processFilter.colSize">
            <dynamic-input
              :index="'filter-preview'"
              :input-object="processFilter"
            />
          </b-col>
          <b-col cols="12" style="margin-bottom: 0.6rem">
            <b-progress max="12" striped>
              <b-progress-bar :value="processFilter.colSize">
                <strong>{{ processFilter.colSize }} / 12</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <filter-sql-variable />
        </b-row>
      </b-col>
    </b-row>
  </b-col>
  <!-- #endregion -->
</template>

<script>
import {
  BRow, BCol, BProgress, BProgressBar,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import DynamicInput from '../../components/input/DynamicInput.vue'
import FilterSqlVariable from './FilterSQLVariable.vue'

export default {
  name: 'filter-preview',
  components: {
    BRow,
    BCol,
    BProgress,
    BProgressBar,
    DynamicInput,
    FilterSqlVariable,
  },
  computed: {
    ...mapGetters(['processFilter']),
  },
}
</script>