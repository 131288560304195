<template>
  <!-- #region Filtreler -->
  <b-col cols="12" md="6">
    <h2 class="d-flex justify-content-between">
      {{ $t("reportcrud.2.title") }}
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        size="sm"
        @click="openNewFilterModal"
      >
        <span class="align-middle">
          {{ $t("reportcrud.2.new_filter_button_text") }}
        </span>
        <feather-icon icon="PlusIcon" class="ml-50" />
      </b-button>
    </h2>
    <b-row class="filters border border-primary rounded mt-1">
      <b-col v-if="filters.length > 0" cols="12">
        <draggable
          v-model="$store.state.report_filters"
          handle='.drag-button'
          class="list-group"
        >
          <b-row
            v-for="(item, index) in filters"
            :key="item.name"
            class="list-group-item filter"
          >
            <b-col cols="9">
              <dynamic-input :index="index" :input-object="item" />
            </b-col>
            <b-col cols="3" class="filter-delete">
              <div
                @mouseover="deleteFilterIndex = index"
                @mouseleave="deleteFilterIndex = null"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="btn-icon"
                  :class="deleteFilterShow(index) ? 'border-left px-1' : ''"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
                <b-button
                  v-show="deleteFilterShow(index)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="btn-icon border-right px-1"
                  @click="deleteFilter(index)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </div>
              <b-button
                v-b-tooltip.hover.top="$t('reportcrud.2.drag_tooltip')"
                variant="warning"
                class="btn-icon drag-button"
              >
                <feather-icon icon="MoveIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.top="$t('reportcrud.2.update_tooltip')"
                variant="info"
                class="btn-icon"
                @click="openUpdateFilterModal(index)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
          </b-row>
        </draggable>
      </b-col>
    </b-row>
    <filter-modal ref="modal" :is-new="isNew" :update-index="updateIndex"/>
  </b-col>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
.filters {
  margin: 0.5rem 0;
  height: 400px;
  max-height: 400px;
  overflow: auto;
  .filter {
    display: flex;
    border: none;
  }
  .filter-delete {
    align-items: flex-start;
    justify-content: space-evenly;
    display: flex;
    margin-top: 22px;
    padding-left: 0;
    .drag-button {
      cursor: move !important;
    }
  }
}
</style>

<script>
import { TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BFormTextarea,
  BFormGroup,
  BFormInvalidFeedback,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import DynamicInput from '../../components/input/DynamicInput.vue'
import FilterModal from './FilterModal.vue'
import { DEFAULT_FILTER, GET_PROCESS_FILTER, SET_OPTION, SET_TYPE, SET_VALUE } from '@/store/services/process-filter'
import { DELETE_FILTER, SET_FILTERS } from '@/store/services/report-filters'
import { mapGetters } from 'vuex'

export default {
  name: 'report-filters',
  components: {
    TabContent,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormTextarea,
    BFormGroup,
    BFormInvalidFeedback,
    draggable,
    DynamicInput,
    FilterModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isNew: true,
      updateIndex:-1,
      deleteFilterIndex:null
    }
  },
  created() {
    this.$store.commit(DEFAULT_FILTER)
  },
  computed:{
    ...mapGetters(['filters'])
  },
  methods: {
    openNewFilterModal(){
      this.isNew = true
      this.updateIndex = -1
      this.$store.commit(DEFAULT_FILTER)
      this.$refs.modal.show()
    },
    openUpdateFilterModal(index){
      this.isNew = false
      this.updateIndex = index
      this.$store.dispatch(GET_PROCESS_FILTER,index).then(filter=>{
        this.$store.commit(SET_TYPE,filter.type)
        return filter
      }).then(filter=>{
        this.$store.commit(SET_OPTION,filter.option)
        return filter
      }).then(filter=>{
        this.$store.commit(SET_VALUE,filter.value)
      }).then(()=>{
        this.$refs.modal.show()
      })
    },
    checkPhase2() {
      return new Promise((resolve, reject) => {
        this.$refs.phase2.validate().then(success => {
          if (success) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    deleteFilterShow(index){
      return index == this.deleteFilterIndex
    },
    deleteFilter(index){
      this.$store.commit(DELETE_FILTER,index)
      this.deleteFilterIndex = null
    }
  },
}
</script>