var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"cols":"12"}},[(_vm.processFilter.type == 1 && !_vm.processFilter.SQLIsRun)?_c('b-form-group',[_c('b-row',[_c('b-col',{staticClass:"filter-select-list",attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("reportcrud.2.filter_select_options.title")))]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.addOption()}}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("reportcrud.2.filter_select_options.new")))]),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"PlusIcon"}})],1)],1)],1),_c('b-row',{staticClass:"filter-select-options-row border border-primary rounded"},[_c('b-col',{attrs:{"cols":"12"}},[_c('draggable',{staticClass:"list-group",attrs:{"handle":".drag-button"},model:{value:(_vm.dragSelect),callback:function ($$v) {_vm.dragSelect=$$v},expression:"dragSelect"}},_vm._l((_vm.selectOptions),function(item,index){return _c('b-row',{key:index,staticClass:"list-group-item filter-select-option"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('reportcrud.2.filter_select_options.value_title'),"label-for":'filter-select-option-value-' + index}},[_c('validation-provider',{attrs:{"name":_vm.$t('reportcrud.2.filter_select_options.value_title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'filter-select-option-value-' + index,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t(
                        'reportcrud.2.filter_select_options.value_placeholder'
                      )},model:{value:(item.id),callback:function ($$v) {_vm.$set(item, "id", $$v)},expression:"item.id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"8","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('reportcrud.2.filter_select_options.label_title'),"label-for":'filter-select-option-text-' + index}},[_c('validation-provider',{attrs:{"name":_vm.$t('reportcrud.2.filter_select_options.label_title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'filter-select-option-text-' + index,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t(
                        'reportcrud.2.filter_select_options.label_placeholder'
                      )},model:{value:(item.label),callback:function ($$v) {_vm.$set(item, "label", $$v)},expression:"item.label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"filter-select-option-delete",attrs:{"cols":"2"}},[(_vm.showDelete)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                  _vm.$t('reportcrud.2.filter_select_options.remove')
                ),expression:"\n                  $t('reportcrud.2.filter_select_options.remove')\n                ",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteOption(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                  _vm.$t('reportcrud.2.filter_select_options.move')
                ),expression:"\n                  $t('reportcrud.2.filter_select_options.move')\n                ",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon drag-button",attrs:{"variant":"warning"}},[_c('feather-icon',{attrs:{"icon":"MoveIcon"}})],1)],1)],1)}),1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }