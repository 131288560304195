<template>
  <!-- #region Sorgu Editörü -->
  <b-col cols="12" md="6">
    <!-- #region Header -->
    <h2 class="mb-1 d-flex justify-content-between">
      {{ $t("reportcrud.3.query") }}
      <b-button
        variant="warning"
        size="sm"
        :disabled="queryRunning"
        @click="runQuery()"
      >
        <span class="align-middle">{{ $t("reportcrud.3.run_query") }}</span>
        <feather-icon icon="CpuIcon" class="ml-50" />
      </b-button>
    </h2>
    <!-- #endregion -->
    <!-- #region Sorgu Editor -->
    <validation-observer ref="queryCheck" tag="form">
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.3.query')"
        rules="required"
      >
        <b-overlay
          :show="queryRunning"
          variant="dark"
          opacity="0.85"
          blur="2px"
          rounded="sm"
        >
          <prism-editor
            v-model="$store.state.reportCrud.sql_query"
            :class="'report-sql-editor rounded ' + skin + '-editor'"
            :highlight="highlighterSQL"
            @click="changePosition($event)"
            @keyup="changePosition($event)"
            @focus="changePosition($event)"
          />
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-overlay>
      </validation-provider>
    </validation-observer>
    <!-- #endregion -->
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BCol, BButton, BOverlay, BFormInvalidFeedback } from "bootstrap-vue";
import { PrismEditor } from "vue-prism-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import { mapGetters } from "vuex";
import { replaceQueryFields } from "@/views/Report/components/js/replaceQueryFields";
import { CHANGE_POSITION, RUN_QUERY } from "@/store/services/report-crud";

export default {
  name: "query-editor",
  components: {
    ValidationObserver,
    ValidationProvider,
    BCol,
    BButton,
    BOverlay,
    BFormInvalidFeedback,
    PrismEditor,
  },
  data() {
    return {
      queryRunning: false,
    };
  },
  computed: {
    ...mapGetters(["filters", "query", "appConfig/getThemeSkin"]),
    skin() {
      return this["appConfig/getThemeSkin"];
    },
  },
  methods: {
    check() {
      return new Promise((resolve) => {
        this.$refs.queryCheck.validate().then((response) => {
          resolve(response);
        });
      });
    },
    highlighterSQL(code) {
      return highlight(code, languages.sql);
    },
    runQuery() {
      this.queryRunning = true;
      const send_query = replaceQueryFields(this.query, this.filters);
      this.$store.dispatch(RUN_QUERY, send_query).then(() => {
        this.queryRunning = false;
      });
    },
    changePosition(event) {
      const first = event.target.selectionStart;
      const last = event.target.selectionEnd;
      this.$store.commit(CHANGE_POSITION, { first, last });
    },
    replaceQueryFields,
  },
};
</script>