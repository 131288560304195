<template>
  <!--#region Sorgu -->
  <b-row>
    <!-- #region Header -->
    <b-col cols="12" class="mb-1">
      <h5 class="mb-0">{{ $t("reportcrud.3.title") }}</h5>
      <small class="text-muted">{{ $t("reportcrud.3.placeholder") }}</small>
      <div class="next-prev-short">
        <a v-b-tooltip.hover.top="$t('reportcrud.prev')" @click="prevTab()">
          <feather-icon icon="ArrowLeftIcon" />
        </a>
        <a v-b-tooltip.hover.top="$t('reportcrud.next')" @click="nextTab()">
          <feather-icon icon="ArrowRightIcon" />
        </a>
      </div>
    </b-col>
    <!-- #endregion -->
    <b-col cols="12" class="mb-1">
      <b-row>
        <tables />
        <query-editor ref="query" />
        <filters />
      </b-row>
    </b-col>
    <b-col cols="12" class="mb-1">
      <b-row>
        <query-raw />
        <query-response />
      </b-row>
    </b-col>
    <query-response-table />
  </b-row>
  <!-- #endregion -->
</template>

<style lang="scss">
.report-sql-editor {
  height: 400px;
  max-height: 400px;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px;
  .prism-editor__editor {
    background: none !important;
    color: #ccc;
  }
}
</style>

<script>
import { BRow, BCol, VBTooltip } from "bootstrap-vue";

import Tables from "./Tables.vue";
import QueryEditor from "./QueryEditor.vue";
import Filters from "./Filters.vue";
import QueryRaw from "./RawQuery.vue";
import QueryResponse from "./QueryResponse.vue";
import QueryResponseTable from "./QueryResponseTable.vue";

export default {
  name: "phase-three",
  components: {
    BRow,
    BCol,
    Tables,
    QueryEditor,
    Filters,
    QueryRaw,
    QueryResponse,
    QueryResponseTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    check() {
      return new Promise((resolve) => {
        this.$refs.query.check().then((response) => {
          resolve(response);
        });
      });
    },
    nextTab() {
      this.$root.$emit("nextTab");
    },
    prevTab() {
      this.$root.$emit("prevTab");
    },
  },
};
</script>