<template>
  <!-- #region Tablo İsimleri -->
  <b-col cols="12" md="3" lg="2" v-if="tableNames">
    <h2 class="mb-1">{{ $t("reportcrud.3.tables") }}</h2>
    <b-input-group>
      <b-input-group-prepend class="search-input-prepend" is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        class="search-input"
        type="text"
        v-model="search"
        :placeholder="$t('reportcrud.3.search_placeholder')"
      />
    </b-input-group>
    <div class="db-table-names border rounded">
      <div v-for="item in searched()" :key="item.id">
        <div class="table border">
          <div class="label">
            <a
              v-b-tooltip.hover.bottom="$t('reportcrud.3.add_query')"
              @click="addQuery(item.label)"
            >
              <p class="m-0 p-0 mr-1">
                {{ item.label }}
              </p>
              <b-badge variant="primary">
                {{ item.children.length }}
              </b-badge>
            </a>
            <a
              v-b-tooltip.hover.bottom="$t('reportcrud.3.columns')"
              @click="item.showColumn = !item.showColumn"
            >
              <feather-icon
                height="23"
                width="23"
                :icon="item.showColumn ? 'ChevronDownIcon' : 'ChevronRightIcon'"
              />
            </a>
          </div>
        </div>
        <div class="columns" v-show="item.showColumn">
          <div
            class="column border ml-2"
            v-for="child in item.children"
            :key="child.id"
          >
            <a
              v-b-tooltip.hover.bottom="$t('reportcrud.3.add_query')"
              @click="addQuery(child.label)"
            >
              <div class="label">
                <feather-icon height="23" width="23" icon="AwardIcon" />
                <p class="m-0 p-0 ml-1">
                  {{ child.label }}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </b-col>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/include";
@import "vue-select/src/scss/global/_variables.scss";
.db-table-names {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  height: calc(400px - 38px);
  max-height: calc(400px - 38px);
  div {
    padding: 0;
    margin: 0;
    .table {
      padding-left: 10px;
      display: flex;
      align-items: center;
      height: 50px;
      border-right: none !important;
      border-left: none !important;
      border-top: none !important;
      width: 100%;
      .label {
        display: flex;
        height: 20px;
        width: 100%;
        a {
          display: flex;
          height: 20px;
          &:first-child {
            width: 90%;
          }
        }
        p {
          white-space: nowrap;
          font-weight: 600;
          font-size: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:hover,
      &:focus {
        border-color: $theme-dark-border-color !important;
        background: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
      }
    }
    .columns {
      .column {
        border-right: none !important;
        border-top: none !important;
        &:last-child {
          border-bottom: none !important;
        }
        .label {
          display: flex;
          align-items: center;
          flex-direction: row;
          height: 40px;
          p {
            width: 100%;
            white-space: nowrap;
            font-weight: 600;
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:hover,
          &:focus {
            border-color: $theme-dark-border-color !important;
            background: $dropdown-link-hover-bg;
            color: $dropdown-link-hover-color;
          }
        }
      }
    }
  }
}
.search-input-prepend {
  .input-group-text {
    border-bottom-left-radius: 0 !important;
  }
}
.search-input {
  border-bottom-right-radius: 0 !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  VBTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BBadge,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { ADD_QUERY } from "@/store/services/report-crud";

export default {
  name: "phase-three",
  components: {
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapGetters(["tableNames"]),
  },
  methods: {
    addQuery(val) {
      this.$store.commit(ADD_QUERY, val);
    },
    searched() {
      if (this.search == "") {
        return this.tableNames.data;
      }
      return this.tableNames.data.filter(
        (item) =>
          item.label.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.children.length > 0 &&
            item.children.findIndex((x) =>
              x.label.toLowerCase().includes(this.search.toLowerCase())
            ) != -1)
      );
    },
  },
};
</script>