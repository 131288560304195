<template>
  <!--#region Yeni Rapor Grubu -->
  <b-modal
    id="new-report-group-modal"
    hide-footer
    centered
    no-close-on-backdrop
    size="md"
    :title="$t('reportcrud.1.new_group.title')"
  >
    <validation-observer
      ref="newReportGroupDef"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('reportcrud.1.new_group.name')"
            label-for="new_report_group_name"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('reportcrud.1.new_group.name')"
              rules="required"
            >
              <b-form-input
                id="new_report_group_name"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('reportcrud.1.new_group.name_placeholder')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('reportcrud.1.new_group.parent')"
            label-for="new_report_group_parent_group"
          >
            <treeselect
              id="new_report_group_parent_group"
              v-model="parent"
              :options="parents"
              :placeholder="$t('reportcrud.1.new_group.parent_placeholder')"
              :show-count="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('reportcrud.1.new_group.description')"
            label-for="new_report_group_description"
          >
            <b-form-textarea
              id="new_report_group_description"
              v-model="description"
              type="textarea"
              :placeholder="
                $t('reportcrud.1.new_group.description_placeholder')
              "
              rows="2"
              max-rows="3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="filter-footer mt-1" @mouseover="checkNewGroup">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          :disabled="invalid"
          @click="createReportGroup"
        >
          {{ $t("reportcrud.1.new_group.save_button_text") }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-modal>
  <!--#endregion -->
</template>

<style lang="scss" scoped>
.filter-footer {
  display: flex;
  padding: 5px 10px;
  justify-content: flex-end;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/views/Report/components/scss/treeselect.scss'
import Ripple from 'vue-ripple-directive'
import { NEW_REPORT_GROUP } from '@/store/services/report-service'

export default {
  name: 'report_new_report_group',
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BButton,
    Treeselect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      parent: null,
      parents: this.$parent.groups,
      description: '',
    }
  },
  methods: {
    createReportGroup() {
      const newReportGroup = {
        group_name: this.name,
        description: this.description,
      }
      if (this.parent != null) {
        newReportGroup.parent_group_id = this.parent
      }
      this.$store
        .dispatch(NEW_REPORT_GROUP, newReportGroup)
        .then(
          response => new Promise(resolve => {
            if (response.status) {
              const newId = response.data.report_group_id
              const newGroup = {
                id: newId,
                label: response.data.group_name,
              }
              if (this.parent != null) {
                let temp = JSON.parse(JSON.stringify(this.$parent.groups))
                this.$parent.groups = this.pushChild(
                  temp,
                  this.parent,
                  newGroup,
                )
              } else {
                this.$parent.groups.push(newGroup)
              }
              this.$bvModal.hide('new-report-group-modal')
              resolve(newId)
            }
          }),
        )
        .then(newId => {
          this.$parent.report_group_id = newId
          this.name = ''
          this.parent = null
          this.description = ''
        })
    },
    pushChild(groups, parent, child) {
      groups.forEach(item => {
        if (item.id === parent) {
          if (!('children' in item)) {
            item.children = []
          }
          item.children.push(child)
          return groups
        }
        if ('children' in item) {
          item.children = this.pushChild(item.children, parent, child)
        }
      })
      return groups
    },
    checkNewGroup() {
      this.$refs.newReportGroupDef.validate()
    },
  },
}
</script>