<template>
  <!-- #region Filtre Türü -->
  <b-col cols="12">
    <b-form-group
      :label="$t('reportcrud.2.filter_type.title')"
      label-for="filter-type"
    >
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.2.filter_type.title')"
        rules="required"
      >
        <treeselect
          id="filter-type"
          v-model="type"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="types"
          :placeholder="$t('reportcrud.2.filter_type.placeholder')"
          :class="errors.length > 0 ? 'is-invalid' : ''"
        />
        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BCol, BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue'

import Treeselect from '@riophae/vue-treeselect'
import { SET_TYPE } from '@/store/services/process-filter'
import { mapGetters } from 'vuex'

export default {
  name: 'filter-type',
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    Treeselect,
  },
  computed: {
    ...mapGetters(['processFilter']),
    type:{
      get(){
        return this.processFilter.type
      },
      set(val){
        this.$store.commit(SET_TYPE,val)
      }
    },
    types() {
      return [
        { id: 0, label: this.$t('reportcrud.2.filter_type.basic_input_label') },
        {
          id: 1,
          label: this.$t('reportcrud.2.filter_type.select_input_label'),
        },
        { id: 2, label: this.$t('reportcrud.2.filter_type.date_input_label') },
      ]
    },
  }
}
</script>