import { replaceSQLVariable } from './replaceSQLVariable'
export function replaceQueryFields(query,filters)
{
  let send_query = query.split('').join('')
  send_query = send_query.replaceAll('\n',' ')
  filters.forEach(item =>
  {
    const tempVal = replaceSQLVariable(item)
    const replace = `{${item.SQLVariable}}`
    send_query = send_query.replaceAll(replace,tempVal)
  })
  return send_query;
}