<template>
  <!--#region Rapor Adı -->
  <b-col cols="12" md="4">
    <b-form-group :label="$t('reportcrud.1.name')" label-for="report_name">
      <validation-provider
        #default="{ errors }"
        :name="$t('reportcrud.1.name')"
        rules="required"
      >
        <b-form-input
          id="report_name"
          v-model="$store.state.reportCrud.report_name"
          :state="errors.length > 0 ? false : null"
          :placeholder="$t('reportcrud.1.name_placeholder')"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </b-col>
  <!--#endregion -->
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";

export default {
  name: "report_name",
  data() {
    return {
      required,
    };
  },
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
  },
};
</script>