<template>
  <!-- #region Rapor Tipi Yardım Modal -->
  <b-modal
    ref="report_type_help_modal"
    hide-footer
    centered
    size="lg"
    :title="$t('reportcrud.4.help_modal.title')"
  >
    <ul>
      <li v-html="$t('reportcrud.4.help_modal.1')"></li>
      <li v-html="$t('reportcrud.4.help_modal.2')"></li>
      <li v-html="$t('reportcrud.4.help_modal.3')"></li>
      <li v-html="$t('reportcrud.4.help_modal.4')"></li>
      <ul>
        <li v-html="$t('reportcrud.4.help_modal.5')"></li>
        <li v-html="$t('reportcrud.4.help_modal.6')"></li>
        <li v-html="$t('reportcrud.4.help_modal.7')"></li>
        <li v-html="$t('reportcrud.4.help_modal.8')"></li>
      </ul>
      <li class="text-info" v-html="$t('reportcrud.4.help_modal.9')"></li>
      <ul>
        <li v-html="$t('reportcrud.4.help_modal.10')"></li>
        <li v-html="$t('reportcrud.4.help_modal.11')"></li>
        <li v-html="$t('reportcrud.4.help_modal.12')"></li>
        <li v-html="$t('reportcrud.4.help_modal.13')"></li>
      </ul>
      <li v-html="$t('reportcrud.4.help_modal.14')"></li>
    </ul>
  </b-modal>
  <!-- #endregion -->
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  name: 'help-modal',
  components: {
    BModal,
  },
  methods:{
    showModal(){
      this.$refs.report_type_help_modal.show()
    }
  }
}
</script>