<template>
  <!-- #region Sorgu Cevabı -->
  <b-col cols="12" md="6">
    <h2 class="mb-1">{{ $t("reportcrud.3.query_response") }}</h2>
    <prism
      :class="'report-sql-editor rounded ' + skin + '-editor'"
      language="javascript"
    >
      {{ $t("reportcrud.3.query_response") }}:{{ response }}
    </prism>
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { BCol } from "bootstrap-vue";
import Prism from "vue-prism-component";
import "prismjs";

import { mapGetters } from "vuex";

export default {
  name: "query-response",
  components: {
    BCol,
    Prism,
  },
  computed: {
    ...mapGetters(["reportData", "appConfig/getThemeSkin"]),
    response() {
      return JSON.stringify(this.reportData, null, 2);
    },
    skin() {
      return this["appConfig/getThemeSkin"];
    },
  },
};
</script>