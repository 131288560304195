<template>
  <!-- #region Seçim Aracı => Seçenekler -->
  <b-col cols="12">
    <b-form-group v-if="processFilter.type == 1 && !processFilter.SQLIsRun">
      <b-row>
        <b-col cols="12" class="filter-select-list">
          <h3>{{ $t("reportcrud.2.filter_select_options.title") }}</h3>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            @click="addOption()"
          >
            <span class="align-middle">{{
              $t("reportcrud.2.filter_select_options.new")
            }}</span>
            <feather-icon icon="PlusIcon" class="ml-50" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="filter-select-options-row border border-primary rounded">
        <b-col cols="12">
          <draggable
            v-model="dragSelect"
            handle=".drag-button"
            class="list-group"
          >
            <b-row
              v-for="(item, index) in selectOptions"
              :key="index"
              class="list-group-item filter-select-option"
            >
              <b-col cols="4">
                <b-form-group
                  :label="$t('reportcrud.2.filter_select_options.value_title')"
                  :label-for="'filter-select-option-value-' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('reportcrud.2.filter_select_options.value_title')"
                    rules="required"
                  >
                    <b-form-input
                      :id="'filter-select-option-value-' + index"
                      v-model="item.id"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="
                        $t(
                          'reportcrud.2.filter_select_options.value_placeholder'
                        )
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="8" sm="6">
                <b-form-group
                  :label="$t('reportcrud.2.filter_select_options.label_title')"
                  :label-for="'filter-select-option-text-' + index"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('reportcrud.2.filter_select_options.label_title')"
                    rules="required"
                  >
                    <b-form-input
                      :id="'filter-select-option-text-' + index"
                      v-model="item.label"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="
                        $t(
                          'reportcrud.2.filter_select_options.label_placeholder'
                        )
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="2" class="filter-select-option-delete">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-tooltip.hover.top="
                    $t('reportcrud.2.filter_select_options.remove')
                  "
                  variant="danger"
                  class="btn-icon"
                  @click="deleteOption(index)"
                  v-if="showDelete"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-tooltip.hover.top="
                    $t('reportcrud.2.filter_select_options.move')
                  "
                  variant="warning"
                  class="btn-icon drag-button"
                >
                  <feather-icon icon="MoveIcon" />
                </b-button>
              </b-col>
            </b-row>
          </draggable>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
.filter-select-option-delete {
  align-items: flex-start;
  justify-content: space-evenly;
  display: flex;
  margin-top: 22px;
  padding-left: 0;
  .drag-button {
    cursor: move !important;
  }
}
.filter-select-list {
  display: flex;
  justify-content: space-between;
}
.filter-select-options-row {
  margin: 0.5rem 0;
  max-height: 400px;
  overflow: auto;
  .filter-select-option {
    display: flex;
    border: none;
    padding: 0.5rem 0;
  }
}
</style>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import { ADD_SELECT_OPTION, DELETE_SELECT_OPTION, SET_SELECT_OPTION } from '@/store/services/process-filter'

export default {
  name: 'filter-select-options',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    VBTooltip,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {
    ...mapGetters(['processFilter','selectOptions']),
    showDelete() {
      return this.selectOptions.length > 1
    },
    dragSelect:{
      get(){
        return this.selectOptions
      },
      set(val){
        this.$store.commit(SET_SELECT_OPTION,val)
      }
    }
  },
  methods: {
    addOption() {
      this.$store.commit(ADD_SELECT_OPTION)
    },
    deleteOption(index) {
      this.$store.commit(DELETE_SELECT_OPTION,index)
    },
  }
}
</script>