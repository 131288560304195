<template>
  <!-- #region Filtre Boyut/SQL -->
  <b-col cols="12">
    <filter-settings-column
    />
    <filter-settings-sql
      v-if="processFilter.type == 1"
    />
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BCol, BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue'

import FilterSettingsColumn from './FilterSettingsColumn.vue'
import FilterSettingsSql from './FilterSettingsSQL.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'filter-option',
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    FilterSettingsColumn,
    FilterSettingsSql,
  },
  computed:{
    ...mapGetters(['processFilter'])
  }
}
</script>