<template>
  <!-- #region Sorgu Cevabı DataTable -->
  <b-col
    v-if="ready && reportData.length > 0 && sqlResponseSettings.length > 0"
    cols="12 mt-1"
  >
    <h2 class="mb-1">{{ $t("reportcrud.3.query_table") }}</h2>
    <common-report-table
      :report-name="$t('reportcrud.3.query')"
      report-master-id="1"
      :table-settings="sqlResponseSettings"
      :table-data="reportData"
    />
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import CommonReportTable from '../../components/CommonReportTable.vue'

export default {
  name: 'query-response-table',
  components: {
    BCol,
    CommonReportTable,
  },
  data(){
    return{
      ready: false
    }
  },
  computed: {
    ...mapGetters(['reportData', 'sqlResponseSettings']),
    check() {
      return this.reportData
    },
  },
  watch: {
    check() {
      new Promise(resolve => {
        this.ready = false
        resolve()
      }).then(() => {
        this.ready = true
      })
    },
  },
}
</script>