<template>
  <!-- #region Sorgu Cevabı -->
  <b-col cols="12" md="6">
    <h2 class="mb-1">{{ $t("reportcrud.3.raw_query") }}</h2>
    <prism-editor
      :class="'report-sql-editor rounded ' + skin + '-editor'"
      :highlight="highlighterSQL"
      v-model="rawQuery"
      readonly
    />
  </b-col>
  <!-- #endregion -->
</template>

<script>
import { BCol } from "bootstrap-vue";
import { PrismEditor } from "vue-prism-editor";
import { highlight, languages } from "prismjs/components/prism-core";

import { mapGetters } from "vuex";
import { replaceQueryFields } from "../../components/js/replaceQueryFields";

export default {
  name: "raw-query",
  components: {
    BCol,
    PrismEditor,
  },
  computed: {
    ...mapGetters(["filters", "query", "appConfig/getThemeSkin"]),
    rawQuery() {
      return replaceQueryFields(this.query, this.filters);
    },
    skin() {
      return this["appConfig/getThemeSkin"];
    },
  },
  methods: {
    highlighterSQL(code) {
      return highlight(code, languages.sql);
    },
  },
};
</script>